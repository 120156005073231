import React from "react"
import Layout from "../components/layout"

export default function Cennik() {
  return (
  <Layout>
    <div className='text-block'>
      <div>
        <p className='sub-title' style={{fontSize: `30px`}}>7.1 ~ 28.6. | 1.9. ~ 22.12</p>
          <table>
            <tr>
              <td>1 osoba</td>
              <td>63,00 €</td>
            </tr>
            <tr>
              <td>2 osoby</td>
              <td>63,00 €</td>
            </tr>
            <tr>
              <td>3 osoby</td>
              <td>83,00 €</td>
            </tr>
            <tr>
              <td>4 osoby</td>
              <td>93,00 €</td>
            </tr>
          </table>
      </div>
  
      <div>
        <p className='sub-title' style={{fontSize: `30px`}}>29.6. ~ 31.8. | 23.12 ~ 6.1.</p>
          <table>
            <tr>
              <td>1 osoba</td>
              <td>70,00 €</td>
            </tr>
            <tr>
              <td>2 osoby</td>
              <td>70,00 €</td>
            </tr>
            <tr>
              <td>3 osoby</td>
              <td>89,00 €</td>
            </tr>
            <tr>
              <td>4 osoby</td>
              <td>99,00 €</td>
            </tr>
          </table>
      </div>
    </div>
    <div className='text-block text-align'>
      Cena za ubytovanie nezahŕňa mestskú daň, ktorá je 3,50€/os./noc. 
      <br/>
      V prípade dlhšieho pobytu (viac ako 6 dní) cena dohodou.
      <br/>
      1 dieťa do 5 rokov zadarmo.
      <br/>
      Domáce zvieratá niesú povolené!
    </div>
  </Layout>
  );
}
    
  